import type { Options } from '../types';

const actions = {
  FETCH_DASHBOARD_REQUEST: 'FETCH_DASHBOARD_REQUEST',
  FETCH_DASHBOARD_SUCCESS: 'FETCH_DASHBOARD_SUCCESS',
  FETCH_GAPS_REQUEST: 'FETCH_GAPS_REQUEST',
  FETCH_GAPS_SUCCESS: 'FETCH_GAPS_SUCCESS',
  FETCH_ERRORS_LIST_REQUEST: 'FETCH_ERRORS_LIST_REQUEST',
  FETCH_ERRORS_LIST_SUCCESS: 'FETCH_ERRORS_LIST_SUCCESS',
  POST_ERROR_COMMENT_REQUEST: 'POST_ERROR_COMMENT_REQUEST',
  POST_ERROR_COMMENT_SUCCESS: 'POST_ERROR_COMMENT_SUCCESS',
  DELETE_ERROR_COMMENT_REQUEST: 'DELETE_ERROR_COMMENT_REQUEST',
  DELETE_ERROR_COMMENT_SUCCESS: 'DELETE_ERROR_COMMENT_SUCCESS',

  fetchDashboard: (payload?: Options) => ({
    type: actions.FETCH_DASHBOARD_REQUEST,
    payload,
  }),
  fetchGaps: () => ({
    type: actions.FETCH_GAPS_REQUEST,
  }),
  fetchErrors: (payload: Options, setLoading: (loading: boolean) => void) => ({
    type: actions.FETCH_ERRORS_LIST_REQUEST,
    payload,
    setLoading,
  }),
  postComment: (
    payload: any,
    filters: any,
    setLoading: (payload: any) => void,
    callback: () => void
  ) => ({
    type: actions.POST_ERROR_COMMENT_REQUEST,
    payload,
    filters,
    setLoading,
    callback,
  }),
  deleteComment: (
    payload: any,
    filters: any,
    setLoading: (payload: any) => void,
    callback: () => void
  ) => ({
    type: actions.DELETE_ERROR_COMMENT_REQUEST,
    payload,
    filters,
    setLoading,
    callback,
  }),
};

export default actions;
