import type { Options } from '../types';

const actions = {
  FETCH_RESULTS_REQUEST: 'FETCH_RESULTS_REQUEST',
  FETCH_RESULTS_SUCCESS: 'FETCH_RESULTS_SUCCESS',
  FETCH_UPSELL_REQUEST: 'FETCH_UPSELL_REQUEST',
  FETCH_UPSELL_SUCCESS: 'FETCH_UPSELL_SUCCESS',
  FETCH_UPSELL_RECURSOS_REQUEST: 'FETCH_UPSELL_RECURSOS_REQUEST',
  FETCH_UPSELL_RECURSOS_SUCCESS: 'FETCH_UPSELL_RECURSOS_SUCCESS',
  FETCH_DOWNSELL_REQUEST: 'FETCH_DOWNSELL_REQUEST',
  FETCH_DOWNSELL_SUCCESS: 'FETCH_DOWNSELL_SUCCESS',
  FETCH_DOWNSELL_RECURSOS_REQUEST: 'FETCH_DOWNSELL_RECURSOS_REQUEST',
  FETCH_DOWNSELL_RECURSOS_SUCCESS: 'FETCH_DOWNSELL_RECURSOS_SUCCESS',
  FETCH_INCOME_REQUEST: 'FETCH_INCOME_REQUEST',
  FETCH_INCOME_SUCCESS: 'FETCH_INCOME_SUCCESS',
  FETCH_CHURN_REQUEST: 'FETCH_CHURN_REQUEST',
  FETCH_CHURN_SUCCESS: 'FETCH_CHURN_SUCCESS',
  FETCH_EXEMPT_REQUEST: 'FETCH_EXEMPT_REQUEST',
  FETCH_EXEMPT_SUCCESS: 'FETCH_EXEMPT_SUCCESS',
  FETCH_NEWCLIENTS_REQUEST: 'FETCH_NEWCLIENTS_REQUEST',
  FETCH_NEWCLIENTS_SUCCESS: 'FETCH_NEWCLIENTS_SUCCESS',
  FETCH_NPS_REQUEST: 'FETCH_NPS_REQUEST',
  FETCH_NPS_SUCCESS: 'FETCH_NPS_SUCCESS',
  FETCH_INDICATIONS_REQUEST: 'FETCH_INDICATIONS_REQUEST',
  FETCH_INDICATIONS_SUCCESS: 'FETCH_INDICATIONS_SUCCESS',
  CONVERT_INDICATION: 'CONVERT_INDICATION',

  fetchResults: (payload: Options & { tipoCliente: 0 | 1 | 2 }) => ({
    type: actions.FETCH_RESULTS_REQUEST,
    payload,
  }),
  fetchUpsell: (payload: Options, setLoading: (loading: boolean) => void) => ({
    type: actions.FETCH_UPSELL_REQUEST,
    payload,
    setLoading,
  }),
  fetchUpsellRecursos: (
    payload: Options,
    setLoading: (loading: boolean) => void
  ) => ({
    type: actions.FETCH_UPSELL_RECURSOS_REQUEST,
    payload,
    setLoading,
  }),
  fetchDownsell: (payload: Options, setLoading: (loading: boolean) => void) => ({
    type: actions.FETCH_DOWNSELL_REQUEST,
    payload,
    setLoading,
  }),
  fetchDownsellRecursos: (
    payload: Options,
    setLoading: (loading: boolean) => void
  ) => ({
    type: actions.FETCH_DOWNSELL_RECURSOS_REQUEST,
    payload,
    setLoading,
  }),
  fetchIncome: (payload: Options, setLoading: (loading: boolean) => void) => ({
    type: actions.FETCH_INCOME_REQUEST,
    payload,
    setLoading,
  }),
  fetchChurn: (
    payload: { motivo: number | null } & Options,
    setLoading: (loading: boolean) => void
  ) => ({
    type: actions.FETCH_CHURN_REQUEST,
    payload,
    setLoading,
  }),
  fetchExempt: (payload: Options, setLoading: (loading: boolean) => void) => ({
    type: actions.FETCH_EXEMPT_REQUEST,
    payload,
    setLoading,
  }),
  fetchNewClients: (payload: Options, setLoading: (loading: boolean) => void) => ({
    type: actions.FETCH_NEWCLIENTS_REQUEST,
    payload,
    setLoading,
  }),
  fetchNPS: (payload: Options, setLoading: (loading: boolean) => void) => ({
    type: actions.FETCH_NPS_REQUEST,
    payload,
    setLoading,
  }),
  fetchIndications: (payload: Options, setLoading: (loading: boolean) => void) => ({
    type: actions.FETCH_INDICATIONS_REQUEST,
    payload,
    setLoading,
  }),
  convertIndication: (
    id: number,
    payload: { codigoEmpresaIndicada: number },
    callback?: (payload: any) => void,
    filters?: Options
  ) => ({
    type: actions.CONVERT_INDICATION,
    id,
    payload,
    callback,
    filters,
  }),
};

export default actions;
