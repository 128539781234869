import { theme } from '@l_ine/core';
import { colorPalette } from '@l_ine/tokens';

import { rgba } from 'polished';

const lightTheme = theme.createTheme({
  palette: {
    primary: {
      color: rgba(0, 0, 0, 1),
      contrast: colorPalette.neutral[0],
    },
    secondary: {
      color: colorPalette.green[300],
      contrast: colorPalette.neutral[900],
    },
  },
});

export default lightTheme;
